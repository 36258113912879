<template>
  <div class="classroom-students-page">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <intract-smart-list
          paginated
          class="mt-4"
          :endpoint="endpoint"
          :item-options="{
            avatar: 'image',
            title: 'full_name',
            subtitle: 'username',
            handleClick: (s) =>
              $router.push({
                name: 'StudentDetails',
                params: { studentId: s.id },
              }),
          }"
        >
          <template v-slot:list-item-right="{ item: student }">
            <v-chip v-if="student.is_logged_in" color="green lighten-4" small
              >Logged In</v-chip
            >
            <v-chip v-else color="red lighten-4" small>Login Pending</v-chip>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";
export default {
  name: "ClassroomStudents",
  mixins: [Mixins.essentials],
  components: {
    IntractSmartList,
  },
  data() {
    return {};
  },
  computed: {
    endpoint() {
      var url = this.endpoints.studentViewSet + "classroom/";
      url = this.Helper.addUrlParams(url, "room=" + this.$route.params.roomId);
      return url;
    },
  },
};
</script>